import { Countries } from "../api/country";

export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_REGIONS = "SET_REGIONS";
export const SET_CITIES = "SET_CITIES";

const setCountries = payload => ({ type: SET_COUNTRIES, payload });
const setRegions = payload => ({ type: SET_REGIONS, payload });
const setCities = payload => ({ type: SET_CITIES, payload });

export const getCountries = () => {
  return dispatch => {
    Countries.getCountries().then(countries =>
      dispatch(setCountries(countries))
    );
  };
};


export const getByCountries = () => {
  return Countries.getCountries().then(response => response);
};


export const getByRegionsByCodeCountry = codeCountry => {
  return Countries.getRegionsByCodeCountry(codeCountry).then(response => response);
};

export const getByCitiesByCodeRegion = (codeCountry, codeRegion) => {
  return Countries.getCitiesByCodeCountryRegion(codeCountry, codeRegion).then(response => response);
};

export const getRegionsByCodeCountry = codeCountry => {
  return dispatch => {
    Countries.getRegionsByCodeCountry(codeCountry).then(regions =>
      dispatch(setRegions(regions))
    );
  };
};

export const getCitiesByCodeCountryRegion = (codeCountry, codeRegion) => {
  return dispatch => {
    Countries.getCitiesByCodeCountryRegion(codeCountry, codeRegion).then(
      cities => dispatch(setCities(cities))
    );
  };
};

export const setEmptyRegions = () => {
  return dispatch => {
    dispatch(setRegions([]));
  };
};

export const setEmptyCities = () => {
  return dispatch => {
    dispatch(setCities([]));
  };
};
