function validateToken(response) {
  if (response.status === 401) {
    //Token invalido, se borra el token del localStorage
    localStorage.removeItem("RECTOKEN");
    // Se redireje la pagina al login
    return (window.location.href = "/login");
  }
  return response;
}

function request(url, options) {
  return fetch(url, options)
    .then(validateToken)
    .then(res => res.json())
    .catch(err => err);
}

function getOptions(method, data = null) {
  const options = {
    method,
    ...(data && { body: JSON.stringify(data) }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("RECTOKEN")}`
    }
  };
  return options;
}

export const GET = url => {
  const options = getOptions("GET");
  return request(url, options);
};

export const POST = (url, data) => {
  const options = getOptions("POST", data);
  return request(url, options);
};

export const DELETE = (url, data) => {
  const options = getOptions("DELETE", data);
  return request(url, options);
};
