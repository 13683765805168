import { POST, GET } from "./";
import { getRegionsByCodeCountry } from "../actions/country";
export const User = {
  create: data => POST(`/api/user`, data),
  createUserCompany: data => POST('api/user/saveUserCompany', data),
  login: data => POST(`/api/user/login`, data),
  sendComments: data => POST(`/api/user/sendComments`, data),
  saveComments: data => POST(`/api/comments/savecomments`, data),
  saveConsultPublic: data => POST(`/api/user/saveConsultPublic`, data),
  passwordRecovery: data => POST(`/api/user/passwordRecovery`, data),
  validToken: data => POST(`/api/user/validToken`, data),
  updatePassword: data => POST(`api/user/updatePassword`, data),
  updateUser: data => POST(`api/user/updateUser`, data),
  getImage: ima => GET(`/api/user/getImageUser/`),
  getUsersToTransfer: () => GET(`/api/user/getUsersToTransfer`),
  userCompanyDelete: id => POST(`/api/user/deleteUserByCompany/${id}`),
  findAllUsers: () => GET(`/api/user/findAllUsers`),
  findUserByActive: data => POST(`/api/user/findUserByActive`, data),
  InfoUserToTransfer: data => POST(`/api/user/getInfoUserToTransfer`, data),
  saveCommentsConsultPublic: data => POST(`/api/user/saveCommentsConsultPublic`, data),
  getByUserId: userId => GET(`/api/user/${userId}`),
  saveSubscription: data => POST(`/api/user/saveSubscription`, data),

};
