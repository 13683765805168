import React from "react";
import { Modal } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";

const HabeasData = ({ showTerms, handleClose, text }) => (
  <Modal show={showTerms} onHide={handleClose}>
    <Modal.Body>
      <div
        className={"ql-editor "}
        style={{
          padding: "20px",
          textAlign: "justify",
          fontSize: "11px",
          maxHeight: "500px",
          overflowY: "scroll",
          color: "black",
          fontWeight: "bolder"
        }}
        dangerouslySetInnerHTML={{
          __html: text
        }}
      />
    </Modal.Body>
  </Modal>
);

export default HabeasData;
