import React from 'react';
import { StyledFooter } from "./StyledFooter";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import { saveSubscription } from "../../../actions/user";

import { getTermCondition } from "../../../../src/actions/termCondition";

import Terms from "../../../../src/components/security/Terms";
import HabeasData from "../../../../src/components/security/HabeasData";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showTerms: false,
      termCondition: "",
      habeasData: "",
      showHabeasData: false

    };
  }

  componentDidMount() {
    getTermCondition().then(response => {
      if (response.status === 1) {
        response.termsConditions.forEach(element => {
          this.setState({
            [element.name]: element.terms
          });
        });
      }
    }
  )
};

  onChange = (name, check = false) => event => {
    return this.setState({
      [name]: event.target.value
    });
  };

  saveSubscription = () => {
    let data = {
      email: this.state.email
    }
    saveSubscription(data).then(response => {
      if (response.status === 1) {
        window.location.reload();
      }
    });
  };

  handleClose = () => {
    this.setState({ showTerms: false, showHabeasData: false });
  };
  
  showTerms = () => {
    this.setState({ showTerms: true });
  };
  showHabeasData = () => {
    this.setState({ showHabeasData: true });
  };


  render() {
    const {
      email,
      showTerms,
      termCondition,
      showHabeasData,
      habeasData
    } = this.state




    return (
      <StyledFooter>

        <Container>
          <Row>
            <Col md={4} xs={12}>
              <h3>Contactenos :</h3>
              <h5>contacto@ecogox.com < br></br>Calle 12 sur#18-168 <br></br>
                Medellin, Colombia </h5>
              <h5>Siguenos <a href="https://twitter.com/EcoGox" target="_black"><i className="mdi mdi-twitter-box" /></a></h5>
            </Col>
            <Col md={4} xs={12}>
              <h3>Recibe Información de EcoGox :</h3>
              <br></br>
              <Form.Control style={{
                borderRadius: "60px"
              }}
                type="text"
                placeholder="Escribe tu email"
                value={email}
                onChange={this.onChange("email")}
              />
              <Form.Group style={{ margin: 0 }}>
                <Form.Check type="checkbox" inline defaultChecked={false} onChange={this.onChange("isTerms", true)} />
                <Form.Label style={{ color: "#000" }} >
                  Acepto los{" "}
                  <span onClick={this.showTerms} style={{ color: "blue", cursor: "pointer" }}>
                    términos y condiciones
                  </span>
                </Form.Label>
              </Form.Group>
              <Button variant="primary" type="button" style={{
                color: "#fff",
                borderRadius: "60px",
                backgroundColor: "rgb(68, 0, 153)"
              }} onClick={this.saveSubscription} >Suscribirme </Button>

            </Col>
            <Col md={4} style={{ textAlign: "center" }} >
              <img src="https://recplatform.blob.core.windows.net/ecoxdocuments/bloqchain.png" width="150px"></img>
              <h4 style={{ color: "#fff" }} >Sello de calidad blockchain</h4>
            </Col>
          </Row>
          <Terms showTerms={showTerms} text={termCondition} handleClose={this.handleClose} />
        </Container >
      </StyledFooter >
      
    );
  }
}
export default Footer;
