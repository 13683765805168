import React, { Component } from "react";
import { connect } from 'react-redux';
import {
	Nav,
	Navbar,
	NavbarBrand,
	Collapse,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledCarousel,
	Progress,
	ListGroup,
	ListGroupItem,
	Row,
	Col,
	Form,
	FormGroup,
	Input
} from 'reactstrap';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as data from './data.js';
import jwt_decode from "jwt-decode";

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import profilephoto from '../../../assets/images/users/9.jpg';

const mapStateToProps = state => ({
	...state
});

class Header extends Component {
	constructor(props) {
		super(props);
		this.toggleMenu = this.toggleMenu.bind(this);
		this.state = {
			isOpen: false,
			infoRolId: "",
			imageUser: '',
			// imageFirm: '',
			companyId: '',
			asignature: {},
			isAsignature: '',
		};
		this.toggle = this.toggle.bind(this);
		this.showMobilemenu = this.showMobilemenu.bind(this);
		this.sidebarHandler = this.sidebarHandler.bind(this);

	}


	/*--------------------------------------------------------------------------------*/
	/*To open Search Bar                                                              */
	/*--------------------------------------------------------------------------------*/
	toggleMenu() {
		document.getElementById('search').classList.toggle('show-search');
	}
	/*--------------------------------------------------------------------------------*/
	/*To open NAVBAR in MOBILE VIEW                                                   */
	/*--------------------------------------------------------------------------------*/
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	/*--------------------------------------------------------------------------------*/
	/*To open SIDEBAR-MENU in MOBILE VIEW                                             */
	/*--------------------------------------------------------------------------------*/
	showMobilemenu() {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	}
	sidebarHandler = () => {
		let element = document.getElementById('main-wrapper');
		switch (this.props.settings.activeSidebarType) {
			case 'full':
			case 'iconbar':
				element.classList.toggle('mini-sidebar');
				if (element.classList.contains('mini-sidebar')) {
					element.setAttribute('data-sidebartype', 'mini-sidebar');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;

			case 'overlay':
			case 'mini-sidebar':
				element.classList.toggle('full');
				if (element.classList.contains('full')) {
					element.setAttribute('data-sidebartype', 'full');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;

			default:
		}
	};


	render() {
		const infoUser = this.props.user.token ? jwt_decode(this.props.user.token) : "";
		return (
			<header
				className="topbar navbarbg"
				data-navbarbg={this.props.settings.activeNavbarBg}
			>
				<Navbar
					className={
						'top-navbar ' +
						(this.props.settings.activeNavbarBg === 'skin6'
							? 'navbar-light'
							: 'navbar-dark')
					}
					expand="md"
				>
					<div
						className="navbar-header"
						id="logobg"
						data-logobg={this.props.settings.activeLogoBg}
					>
						{/*--------------------------------------------------------------------------------*/}
						{/* Mobile View Toggler  [visible only after 768px screen]                         */}
						{/*--------------------------------------------------------------------------------*/}
						<span
							className="nav-toggler d-block d-md-none text-white"
							onClick={this.showMobilemenu}
						>
							<i className="ti-menu ti-close" />
						</span>
						{/*--------------------------------------------------------------------------------*/}
						{/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
						{/*--------------------------------------------------------------------------------*/}
						<NavbarBrand >

							{/* <span className="logo-text" >
								<img src={logodarktext} alt="homepage" className="dark-logo" width="250px"
								>
								</img>
							</span> */}


							{infoUser === "" && (
								<Col>
									<Link to="/registerAccount" style={{ color: "#fff" }}>Crear Cuenta</Link>
								</Col>
							)}
							{infoUser === "" && (
								<Col>
									<Link to="/login" style={{ color: "#FF8000" }}>| Iniciar Sesión</Link>
								</Col>
							)}


						</NavbarBrand>
						{/*--------------------------------------------------------------------------------*/}
						{/* Mobile View Toggler  [visible only after 768px screen]                         */}
						{/*--------------------------------------------------------------------------------*/}
						<span
							className="topbartoggler d-block d-md-none text-white"
							onClick={this.toggle}
						>
							<i className="ti-more" />
						</span>
					</div>
					<Collapse
						className="navbarbg"
						isOpen={this.state.isOpen}
						navbar
						data-navbarbg={this.props.settings.activeNavbarBg}
					>
						<Nav className="float-left" navbar>
							{/* <NavItem>
								<NavLink
									href="#"
									className="d-none d-md-block"
									onClick={this.sidebarHandler}
								>
									<i className="ti-menu" />
								</NavLink>
							</NavItem> */}
							{/*--------------------------------------------------------------------------------*/}
							{/* Start Search-box toggle                                                        */}
							{/*--------------------------------------------------------------------------------*/}

							{/*--------------------------------------------------------------------------------*/}
							{/* End Search-box toggle                                                          */}
							{/*--------------------------------------------------------------------------------*/}
							{/*--------------------------------------------------------------------------------*/}
							{/* Start Mega Menu Dropdown                                                       */}
							{/*--------------------------------------------------------------------------------*/}
							<UncontrolledDropdown nav inNavbar className="mega-dropdown">
								<DropdownMenu>
									<Row>
										{/*--------------------------------------------------------------------------------*/}
										{/* Carousel [Item-1]                                                              */}
										{/*--------------------------------------------------------------------------------*/}
										<Col xs="12" sm="12" md="12" lg="3">
											<h5 className="mb-3 text-uppercase">RECS</h5>
											<UncontrolledCarousel items={data.items} />
										</Col>
										{/*--------------------------------------------------------------------------------*/}
										{/* Progress [Item-2]                                                              */}
										{/*--------------------------------------------------------------------------------*/}
										<Col xs="12" sm="12" md="12" lg="3">
											<h5 className="mb-3 text-uppercase">Estadísticas</h5>
											<div className="d-flex no-block align-items-center mb-2">
												<span>Proyectos registrados</span>
												<div className="ml-auto">
													<span className="text-primary">
														<i className="mdi mdi-chart-areaspline" />
													</span>
												</div>
											</div>
											<Progress className="mb-3" animated value={2 * 5} />
											<div className="d-flex no-block align-items-center mb-2">
												<span>Proyectos Validados</span>
												<div className="ml-auto">
													<span className="text-success">
														<i className="mdi mdi-chart-line" />
													</span>
												</div>
											</div>
											<Progress
												className="mb-3"
												animated
												color="success"
												value="10"
											/>
											<div className="d-flex no-block align-items-center mb-2">
												<span>Cuentas</span>
												<div className="ml-auto">
													<span className="text-danger">
														<i className="mdi mdi-chart-arc" />
													</span>
												</div>
											</div>
											<Progress
												className="mb-3"
												animated
												color="danger"
												value={50}
											/>
											<div className="d-flex no-block align-items-center mb-2">
												<span>Certificados generados</span>
												<div className="ml-auto">
													<span className="text-warning">
														<i className="mdi mdi-chart-pie" />
													</span>
												</div>
											</div>
											<Progress
												className="mb-3"
												animated
												color="warning"
												value={70}
											/>
										</Col>
										{/*--------------------------------------------------------------------------------*/}
										{/* Contact Us [Item-3]                                                            */}
										{/*--------------------------------------------------------------------------------*/}
										<Col xs="12" sm="12" md="12" lg="3">
											<h5 className="mb-3 text-uppercase">CONTACTENOS</h5>
											<Form>
												<FormGroup>
													<Input
														type="text"
														name="name"
														id="textname"
														placeholder="Ingrese el nombre"
													/>
												</FormGroup>
												<FormGroup>
													<Input
														type="email"
														name="email"
														id="exampleEmail"
														placeholder="Ingrese el email"
													/>
												</FormGroup>
												<FormGroup>
													<Input
														type="textarea"
														name="text"
														id="exampleText"
														placeholder="mensaje"
													/>
												</FormGroup>
												<Button color="info">Contactar</Button>
											</Form>
										</Col>
										{/*--------------------------------------------------------------------------------*/}
										{/* List Style [Item-4]                                                            */}
										{/*--------------------------------------------------------------------------------*/}
										<Col xs="12" sm="12" md="12" lg="3">
											<h5 className="mb-3 text-uppercase">Lista de documentos</h5>
											<ListGroup flush>
												<ListGroupItem
													tag="a"
													href=""
													className="border-0 pl-0 text-muted pt-0"
												>
													<i className="fa fa-check text-success mr-2" />
													Cras justo odio
												</ListGroupItem>
												<ListGroupItem
													tag="a"
													href=""
													className="border-0 pl-0 text-muted pt-0"
												>
													<i className="fa fa-check text-success mr-2" />
													Dapibus ac facilisis in
												</ListGroupItem>
												<ListGroupItem
													tag="a"
													href=""
													className="border-0 pl-0 text-muted pt-0"
												>
													<i className="fa fa-check text-success mr-2" />
													Morbi leo risus
												</ListGroupItem>
												<ListGroupItem
													tag="a"
													href=""
													className="border-0 pl-0 text-muted pt-0"
												>
													<i className="fa fa-check text-success mr-2" />
													Porta ac consectetur ac
												</ListGroupItem>
												<ListGroupItem
													tag="a"
													href=""
													className="border-0 pl-0 text-muted pt-0"
												>
													<i className="fa fa-check text-success mr-2" />
													Vestibulum at eros
												</ListGroupItem>
											</ListGroup>
										</Col>
									</Row>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
						<Nav className="ml-auto float-right" navbar>
							{this.props.user.token && <UncontrolledDropdown nav inNavbar>

								<DropdownToggle nav caret className="pro-pic">
									<Row>
										<Col md={3}>
											<h3 style={{ color: "#fff", marginTop: "11px" }}>Perfil</h3>
										</Col>
										<Col md={9}>
											{infoUser.asignature.user.module === "" || infoUser.isAsignature === false && (
												<Col xs={4}>
													<img width="31" height="30" className="rounded-circle" alt="user" src={profilephoto} ></img>
												</Col>
											)}
											{infoUser.asignature.user.module && (
												<Col xs={4}>
													<img width="31" height="30" className="rounded-circle" alt="user" src={`api/image/${infoUser.asignature.user.module}/${infoUser.asignature.user.time}/${infoUser.asignature.user.name}`} ></img>
												</Col>
											)}
										</Col>
									</Row>



								</DropdownToggle>
								<DropdownMenu right className="user-dd">
									<div className="d-flex no-block align-items-center p-3 mb-2 border-bottom">
										<div className="">
											{infoUser.asignature.user.module === "" && (
												<Col xs={4}>
													<img alt="user" className="rounded" width="100" height="100" src={profilephoto} ></img>
												</Col>
											)}
											{infoUser.asignature.user.module && (
												<Col xs={4}>
													<img alt="user" className="rounded" width="100" height="100" src={`api/image/${infoUser.asignature.user.module}/${infoUser.asignature.user.time}/${infoUser.asignature.user.name}`} ></img>
												</Col>
											)}

										</div>
										<div className="ml-3">
											<h4 className="mb-0">{infoUser.name}</h4>
											<p className="text-muted mb-0">{infoUser.email}</p>
											<br></br>
											<Link to="/myAccount" style={{ color: "#440099" }} >
												<Button variant="primary" >
													Ver Perfil
												</Button>
											</Link>
										</div>
									</div>
									{infoUser.accountType !== "super" && (
										<DropdownItem tag={Link} to = "/my-projects" style={{ color: "#440099" }}>
											<i className="ti-wallet mr-1 ml-1"> Mis proyectos </i>
										</DropdownItem>
									)}
									{infoUser.accountType !== "super"  && (
									<DropdownItem tag = {Link} to="/my-request" style={{ color: "#440099" }} >
										<i className="ti-menu-alt mr-1 ml-1"> Mis solicitudes </i>
									</DropdownItem>
									)}
									<DropdownItem className="border-bottom" tag={Link} to="/change-password" style={{ color: "#440099" }}>
										<i className="ti-lock mr-1 ml-1" > Cambiar contraseña </i>
									</DropdownItem>
									{infoUser.accountType === "super" && (
										<>
										<DropdownItem tag = {Link} to="/billing" style={{ color: "#440099" }} >
											<i className="ti-wallet mr-1 ml-1"> Facturación </i>
										</DropdownItem>
										<DropdownItem tag = {Link} to="/administration-result" style={{ color: "#440099" }} >
											<i className="ti-stats-up mr-1 ml-1"> Resultados </i>
										</DropdownItem>
										</>
									)}
									{infoUser.rol === 1  && infoUser.accountType !== "super" && (
										<DropdownItem className="border-bottom" tag= {Link} to="/administrationUserCompany" style={{ color: "#440099" }}>
											<i className="ti-clipboard mr-1 ml-1" > Administración de usuarios </i>
										</DropdownItem>
									)}
									<DropdownItem onClick={() => { localStorage.removeItem('RECTOKEN'); window.location.href = '/'; }}>
										<i className="fa fa-power-off mr-1 ml-1" /> Cerrar sesión
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
							}
							{/*--------------------------------------------------------------------------------*/}
							{/* End Profile Dropdown                                                           */}
							{/*--------------------------------------------------------------------------------*/}
						</Nav>
					</Collapse>
				</Navbar>
			</header>
		);
	}
}
export default connect(mapStateToProps)(Header);
