import { SET_COUNTRIES, SET_REGIONS, SET_CITIES } from '../actions/country';

const initialState = {
  countries: [],
  regions: [],
  cities: []
};

export const general = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRIES: {
      return {
        ...state,
        countries: action.payload
      };
    }
    case SET_REGIONS: {
      return {
        ...state,
        regions: action.payload
      };
    }
    case SET_CITIES: {
      return {
        ...state,
        cities: action.payload
      };
    }
    default:
      return state;
  }
};