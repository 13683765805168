import { User } from "../api/user";

export const SET_TOKEN = "SET_TOKEN";
const setToken = payload => ({ type: SET_TOKEN, payload });

export const setUser = data => {
  return User.create(data).then(response => response);
};

export const saveSubscription = data => {
  return User.saveSubscription(data).then(response => response);
};

export const getByUserId = userId => {
  return User.getByUserId(userId).then(response => response);
};


export const getInfoUserToTransfer = data => {
  return User.InfoUserToTransfer(data).then(response => response);
};

export const createUserCompany = data => {
  return User.createUserCompany(data).then(response => response);
};

export const userAuthentication = data => {
  return User.login(data).then(response => response);
};

export const sendComments = data => {
  return User.sendComments(data).then(response => response);
};


export const saveComments = data => {
  return User.saveComments(data).then(response => response);
};


export const saveCommentsConsultPublic = data => {
  return User.saveCommentsConsultPublic(data).then(response => response);
};

export const saveConsultPublic = data => {
  return User.saveConsultPublic(data).then(response => response);
};

export const userPasswordRecovery = data => {
  return User.passwordRecovery(data).then(response => response);
};

export const validToken = data => {
  return User.validToken(data).then(response => response);
};

export const setPassword = data => {
  return User.updatePassword(data).then(response => response);
};

export const getToken = token => {
  return dispatch => {
    dispatch(setToken(token));
  };
};

export const setInfoUser = data => {
  return User.updateUser(data).then(response => response);
};

export const deleteUserCompany = id => {
  return User.userCompanyDelete(id).then(response => response)
}

export const findAllUsers = () => {
  return User.findAllUsers().then(response => response)
}

export const getUsersToTransfer = () => {
  return User.getUsersToTransfer().then(response => response)
}

export const findUserByActive = data => {
  return User.findUserByActive(data).then(response => response)
}







