import asyncComponent from "./../components/asyncComponent";
import jwt_decode from "jwt-decode";

export const pageInit = {
  generator: "/my-projects",
  certifier: "/my-projects",
  super: "/project/list/public",
  validator: "/my-projects",
  business: "/my-projects",
  user: "/my-clients",
  client: "/my-clients",
  broker: "/certificates-broker",
};

const PRIVATE = {
  brokerProjects: {
    collapse: true,
    name: "Proyectos",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-arrange-send-backward",
    extra: "",
    child: [

      {
        path: "/projectList",
        name: "Todos los Proyectos",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/ProjectList")
        )
      },
      {
        path: "/my-projects",
        name: "Mis Proyectos",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/MyProjects")
        )
      },
      {
        path: "/my-transfered",
        name: "Mis disponibles",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/MyTransfered")
        )
      },
    ],
  },
  brokerBriefcase: {
    collapse: true,
    name: "Portafolio",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-arrange-send-backward",
    extra: "",
    child: [
      {
        path: "/certificates-broker",
        name: "Mis RECs",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/broker/certificates")
        )
      },
      {
        path: "/transferTo",
        name: "Transferir",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/transfer/toTransfer")
        )
      },
      {
        path: "/myAvailables",
        name: "Reportes",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/transfer/myAvailables")
        )
      },
      {
        path: "/listTransfer",
        name: "Histórico transacciones",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/transfer/certificatesAvailableList")
        )
      },
    ],
  },

  brokerClients: {
    collapse: true,
    name: "Clientes",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-arrange-send-backward",
    extra: "",
    child: [
      {
        path: "/cancel-certified",
        name: "Redimir certificados",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/TransferCertification")
        )
      },
      {
        path: "/add_client",
        isMenu: false,
        name: "Añadir cliente",
        icon: "mdi mdi-adjust",
        component: asyncComponent(() =>
          import("./../components/project/AddClient")
        )
      },

      {
        path: "/my-clients",
        name: "Mis clientes",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/clients/companyClients")
        )
      },
      {
        path: "/edit-clients",
        name: "Editar clientes",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/clients/editClients")
        )
      },
      {
        path: "/myRedemptions",
        isMenu: false,
        name: "Redenciones",
        component: asyncComponent(() =>
          import("./../components/project/myRedemptions"))
      }
    ],
  },

  create_project: {
    collapse: true,
    name: "Proyectos",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-arrange-send-backward",
    extra: "",
    child: [

      {
        path: "/projectCreate",
        name: "Crear Proyecto",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/ProjectSteps")
        )
      },
      {
        path: "/my-projects",
        name: "Mis Proyectos",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/MyProjects")
        )
      },
      {
        path: "/certification-request",
        name: "Solicitar certificación",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/certificationRequest")
        )
      },
      {
        path: "/projectList",
        name: "Todos los Proyectos",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/ProjectList")
        )
      },
      {
        path: "/projectEdit/:projectId",
        isMenu: false,
        component: asyncComponent(() =>
          import("./../components/project/ProjectSteps")
        )
      },
    ]
  },
  briefcase: {
    collapse: true,
    name: "Portafolio",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-briefcase-check",
    extra: "",
    child: [
      {
        path: "/transferTo",
        name: "Transferir",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/transfer/toTransfer")
        )
      },
      {
        path: "/myAvailables",
        name: "Reportes",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/transfer/myAvailables")
        )
      },
      {
        path: "/listTransfer",
        name: "Histórico Transferencias",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/transfer/certificatesAvailableList")
        )
      }
    ]
  },
  clients: {
    collapse: true,
    name: "Clientes",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-ticket-account",
    extra: "",
    child: [
      {
        path: "/cancel-certified",
        name: "Redimir certificados",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/TransferCertification")
        )
      },
      {
        path: "/my-clients",
        name: "Mis clientes",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/clients/companyClients")
        )
      },
      {
        path: "/add_client",
        isMenu: false,
        name: "Añadir cliente",
        icon: "mdi mdi-adjust",
        component: asyncComponent(() =>
          import("./../components/project/AddClient")
        )
      },
      {
        path: "/edit-clients",
        name: "Editar clientes",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/clients/editClients")
        )
      },
      {
        path: "/myRedemptions",
        name: "Redenciones",
        isMenu: false,
        component: asyncComponent(() =>
          import("./../components/project/myRedemptions"))
      }
    ]
  },
  onlyClients: {
    collapse: true,
    name: "Clientes",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-ticket-account",
    extra: "",
    child: [
      {
        path: "/my-clients",
        name: "Mis clientes",
        icon: "mdi mdi-adjust",
        component: asyncComponent(() =>
          import("./../components/clients/companyClients")
        )
      }
    ]
  },
  onlyClientsProjects: {
    collapse: true,
    name: "Proyectos",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-arrange-send-backward",
    extra: "",
    child: [
      {
        path: "/projectList",
        name: "Todos los Proyectos",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/ProjectList")
        )
      }
    ]
  },
  administration: {
    collapse: true,
    name: "Administración",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-table-edit",
    extra: "",
    child: [

      {
        path: "/administrationUsers",
        name: "Participantes",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/security/Administration/administrationUsers")
        )
      },
      {
        path: "/administrationClients",
        name: "Clientes finales",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/security/Administration/administrationClients")
        )
      },
    ]
  },
  administrationProjects: {
    collapse: true,
    name: "Proyectos",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-table-edit",
    extra: "",
    path: "/project/list/public",
    clickable: true,
    child: [
      {
        path: "/projectVerification",
        name: "Verificación proyectos",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/admistration/verificationProject")
        )
      },
      {
        path: "/certifications",
        name: "Certificaciones",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/admistration/Certifications")
        )
      }
    ]
  },
  administrationCertificates: {
    collapse: true,
    name: "Certificados",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-table-edit",
    extra: "",
    child: [
      {
        path: "/manual-certificates",
        name: "Certificación manual",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("../components/project/ManualCertificates")
        )
      },
      {
        path: "/pending-request",
        name: "Solicitudes",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/admistration/pendingRequest")
        )
      }
    ]
  },
  administrationResult: {
    collapse: true,
    clickable: true,
    path: "/redemptions",
    name: "Redenciones",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-table-edit",
    extra: "",
    component: asyncComponent(() =>
      import("./../components/project/admistration/Redemptions")
    ),
    child: [
      {
        path: "/cancellation-certificates",
        name: "Anulación de redenciones",
        icon: "mdi mdi-adjust",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../components/project/admistration/CancellationCertificates")
        )
      }
    ]
  }
};

const SESSION = {
  weinfo: {
    collapse: true,
    name: "Nosotros",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-ticket-account",
    extra: "",
    child: [
      {
        //path: "/",
        path: "/about",
        name: "¿Quiénes somos?",
        icon: "mdi mdi-view-dashboard",
        isMenu: true,
        component: asyncComponent(() => import("./../container/About"))
      },
      {
        path: "/functioning",
        //path: "/",
        name: "Funcionamiento",
        icon: "mdi mdi-widgets",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../container/InfoRegisterProject")
        )
      },
    ]
  },
  means: {
    collapse: true,
    name: "Recursos",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-ticket-account",
    extra: "",
    child: [

      {
        //path: "/",
        path: "/protocol",
        name: "Nuestro protocolo",
        icon: "mdi mdi-toggle-switch",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../container/standard")
        )
      },
      {
        //path: "/",
        path: "/links",
        name: "Enlaces de interés",
        icon: "mdi mdi-toggle-switch",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../container/links")
        )
      },
      {
        //path: "/",
        path: "/FrequentQuestions",
        name: "FAQ",
        icon: "mdi mdi-toggle-switch",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../container/FrequentQuestions")
        )
      },
      {
        path: "/user_my_certificates",
        name: "Mis certificados",
        icon: "mdi mdi-account-circle",
        isMenu: true,
        component: asyncComponent(() => import("../container/userFinal"))
      },
    ]
  },
  started: {
    collapse: true,
    name: "Comienza aquí",
    state: "dashboardpages",
    isMenu: true,
    icon: "mdi mdi-ticket-account",
    extra: "",
    child: [
      {
        //path: "/",
        path: "/how_singup",
        name: "¿Cómo registrarme?",
        icon: "mdi mdi-toggle-switch",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../container/howSingup")
        )
      },
      {
        //path: "/",
        path: "/documents _soport",
        name: "Documentos de soporte",
        icon: "mdi mdi-toggle-switch",
        isMenu: true,
        component: asyncComponent(() =>
          import("./../container/documentsSoport")
        )
      }
    ]
  },

  registerAccount: {
    //path: "/",
    path: "/registerAccount",
    name: "Crear cuenta",
    icon: "mdi mdi-account-box",
    isMenu: false,
    component: asyncComponent(() => import("./../components/security/Register"))
  },
  session: {
    //path: "/",
    path: "/login",
    name: "Iniciar sesión",
    icon: "mdi mdi-account-circle",
    isMenu: false,
    component: asyncComponent(() => import("./../components/security/Login"))
  },
  publicConsultation: {
    //path: "/",
    path: "/public-consultation",
    name: "Consulta pública",
    icon: "mdi mdi-folder-multiple",
    isMenu: true,
    component: asyncComponent(() => import("./../container/publicConsultation"))
  },
  contact: {
    //path: "/",
    path: "/contact",
    name: "Contacto",
    icon: "mdi mdi-account-circle",
    isMenu: true,
    component: asyncComponent(() => import("./../container/Contact"))
  },
  projects: {
    //path: "/",
    path: "/project/list/public",
    name: "Proyectos",
    icon: "mdi mdi-folder-multiple",
    isMenu: true,
    component: asyncComponent(() =>
      import("./../components/project/projectListPublic")
    )
  },
};

export const ThemeRoutes = token => {
  const components = [];

  if (token) {
    const infoUser = jwt_decode(token);

    infoUser.componentAccountType.forEach(element => {
      if (PRIVATE[element.component]) {
        components.push(PRIVATE[element.component]);
      }
    });

    if (infoUser.isAsignature === false) {
      PRIVATE["create_project"]["child"][0].component = asyncComponent(() =>
        import("../components/security/MyAccount")
      );
    }
    infoUser.infoRols.forEach(rols => {

      if (rols.rolId === 2 && rols.active === 0) {
        PRIVATE["create_project"]["child"][0].component = asyncComponent(() =>
          import("../components/security/notPermissions")
        );
      }
      if (rols.rolId === 3 && rols.active === 0) {
        PRIVATE["briefcase"]["child"][0].component = asyncComponent(() =>
          import("../components/security/notPermissions")
        );
      }
      if (rols.rolId === 4 && rols.active === 0) {
        PRIVATE["clients"]["child"][0].component = asyncComponent(() =>
          import("../components/security/notPermissions")
        );
      }
      if (rols.rolId === 1 && rols.active === 0 && window.location.pathname === "/administrationUserCompany") {
        window.location.href = "/notPermission"
      }
    })


  } else {

    components.push(SESSION["weinfo"]);
    components.push(SESSION["registerAccount"]);
    components.push(SESSION["session"]);
    components.push(SESSION["projects"]);
    components.push(SESSION["means"]);
    components.push(SESSION["started"]);
    components.push(SESSION["contact"]);

  }

  let ThemeRoutes = [
    {
      navlabel: true,
      name: "Personal",
      icon: "mdi mdi-dots-horizontal"
    },

    {
      path: "/",
      name: "Home",
      icon: "mdi mdi-view-dashboard",
      isMenu: false,
      component: asyncComponent(() => import("./../container/Home"))
    },
    {
      path: "/administration/user",
      name: "User",
      icon: "mdi mdi-view-dashboard",
      isMenu: false,
      component: asyncComponent(() => import("./../components/security/Administration/administrationUserCompany"))
    },
    {
      path: "/comments-public-consultation",
      name: "Comments",
      icon: "mdi mdi-view-dashboard",
      isMenu: false,
      component: asyncComponent(() => import("./../container/commentsPublicConsultation"))
    },
    {
      path: "/projects/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/ProjectView")
      )
    },
    {
      path: "/certification-request/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/certificationRequest")
      )
    },
    {
      path: "/transferTo/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/transfer/toTransfer")
      )
    },
    {
      path: "/cancel-certified/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/TransferCertification")
      )
    },
    {
      path: "/my_project/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/ProjectView")
      )
    },
    {
      path: "/my_project/my_folder/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/ProjectViewByMyFolder")
      )
    },
    {
      path: "/project/public/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/projectViewPublic")
      )
    },
    {
      path: "/project/public/my_folder/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/FolderMyProjectsCertificationList")
      )
    },
    {
      path: "/project/view/by_admin/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/admistration/viewProjectByVerification")
      )
    },
    {
      path: "/UpdateClient/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/UpdateClient")
      )
    },
    {
      path: "/UpdateMyClient/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/UpdateMyClient")
      )
    },
    {
      path: "/project/view/edit/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/projectEdit")
      )
    },
    {
      path: "/project/view/editProject/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/ProjectSteps")
      )
    },
    {
      path: "/project/view/editUnit/:projectId/:unitId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/projectEditUnit")
      )
    },
    {
      path: "/project/view/editUnitAd/:projectId/:unitId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/projectEditUnitAd")
      )
    },
    {
      path: "/project/view/Unit/:projectId/:unitId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/admistration/projectUnit")
      )
    },
    {
      path: "/users_by_id/:userId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/security/userView")
      )
    },
    {
      path: "/add-unit-generation/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("../components/project/addUnitByProjectId")
      )
    },
    {
      path: "/emit-certifications/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("../components/project/ProjectEmitCertificationList")
      )
    },
    {
      path: "/emit-certifications/my_folder/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("../components/project/ProjectEmitCertificationListFolder")
      )
    },
    {
      path: "/myAccount",
      isMenu: false,
      component: asyncComponent(() =>
        import("../components/security/MyAccount")
      )
    },
    {
      path: "/client_by_aprrove/:clientId",
      isMenu: false,
      component: asyncComponent(() =>
        import("../components/security/Administration/AprroveClientByAdmin")
      )
    },
    {
      path: "/password-recovery",
      isMenu: false,
      component: asyncComponent(() =>
        import("../components/security/passwordRecovery")
      )
    },
    {
      path: "/billing",
      isMenu: false,
      component: asyncComponent(() =>
        import("../components/security/Administration/administrationBilling")
      )
    },
    {
      path: "/administration-result",
      isMenu: false,
      component: asyncComponent(() =>
        import("../components/project/admistration/AdministrationResult")
      )
    },
    {
      path: "/redemptions",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/admistration/Redemptions"))
    },

    {
      path: "/project/list/public",
      name: "Todos los Proyectos",
      icon: "mdi mdi-adjust",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/projectListPublic")
      )
    },
    {
      path: "/my-request",
      isMenu: false,
      component: asyncComponent(() =>
        import("../components/project/MyPendingRequest")
      )
    },
    {
      path: "/change-password",
      isMenu: false,
      component: asyncComponent(() =>
        import("../components/security/ChangePassword")
      )
    },
    {
      path: "/cancellationProject/:projectId",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/project/ProjectCancellation")
      )
    },
    {
      path: "/myAccount",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/security/MyAccount")
      )
    },
    {
      path: "/view_client/:id",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/clients/viewClient")
      )
    },
    {
      path: "/notPermission",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/security/notPermissions")
      )
    },
    {
      path: "/administrationUserCompany",
      isMenu: false,
      component: asyncComponent(() =>
        import("./../components/security/Administration")
      )
    }

  ];

  components.forEach(element => {
    ThemeRoutes.push(element);
  });

  return ThemeRoutes;
};

export default ThemeRoutes;
