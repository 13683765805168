import styled from 'styled-components';

export const StyledFooter = styled.footer`
  background: #a3a4a6;
  padding: 2rem 1rem;  
  color:  #000;
  border-top: 2px solid #ff8000;
  .container{
    h4{
      font-size: 1.25rem;
      margin-bottom: 5px;
      color:  #000;
    }
    h3{
      font-size: 1.25rem;
      margin-bottom: 5px;
      color:  #000;
    }
    h5{
      font-size: 1.25rem;
      color:  #000;
    }
    address ul{
      padding: 0px;
      margin: 0px;
    }
    address ul li{
      display: inline-block;
      vertical-aling: top;
      width: 100%;
    }
  } 
`;